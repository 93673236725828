<template>
  <div class="billboardSet">
    <div class="topThree container-fluid">
      <div
        v-if="loadStatus == 1"
        class="row"
      >
        <TheLoadingRoller class="billboardSet__loading" />
      </div>
      <p
        v-else-if="top3.length == 0"
        class="topThree__noData"
      >
        暫無資料
      </p>
      <transition name="topThreeTransition">
        <ul
          v-if="loadStatus == 2 && top3.length > 0"
          class="topThree__list row"
        >
          <li
            v-for="(item, billbordIdx) in top3"
            :key="item.mid"
            class="topThree__listItem col col-4"
          >
            <p class="topThree__listItemNum">
              NO.{{ billbordIdx * 1 + 1 }}
            </p>
            <div
              class="topThree__listItemImg"
              :style="billboardImgStyle(item.img)"
            />
            <p class="topThree__listItemName">
              <span>{{ item.name }}</span>
              <span class="topThree__listItemNameSlash">/</span><br class="topThree__listItemNameBr">
              {{ "分數:" + item.sum }}
            </p>
          </li>
        </ul>
      </transition>
    </div>
    <div
      v-if="loadStatus == 2 && all.length > 0"
      class="billboardSet__allList"
    >
      <table>
        <tr
          v-for="(item, billbordIdx) in all"
          :key="item.mid"
        >
          <th>
            <span class="billboardSet__num">NO.{{ billbordIdx * 1 + 4 }}</span>
          </th>
          <th>
            <img
              class="billboardSet__allListAvatar"
              :src="item.img"
              alt=""
            >
            {{ item.name }}
          </th>
          <td>
            <span class="billboardSet__rateDeco">目前得</span>分數:
            {{ item.sum }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import billboardMixin from '@/mixins/billboardMixin'

export default {
  mixins: [billboardMixin],
  computed: {
    ...mapState({
      all: function (state) {
        return this.currData &&
          this.currData.data &&
          this.currData.data.length > 3
          ? this.currData.data.slice(3)
          : []
      }
    })
  }
}
</script>
<style lang="scss" scoped>
@import "../scss/vars";
$helfGutter-topThree: 10px;
$helfGutter-topThree-xxxs: 5px;

.billboardSet__loading {
  margin: auto;
  margin: 10% auto;
}

.topThree__noData {
  padding: 80px 0;
}

.topThree.container-fluid {
  padding: 0;

  .row {
    margin-right: -$helfGutter-topThree;
    margin-left: -$helfGutter-topThree;
  }
  .col {
    padding-left: $helfGutter-topThree;
    padding-right: $helfGutter-topThree;
  }
  @media (max-width: $maxW-xxs) {
    .col {
      padding-left: $helfGutter-topThree-xxxs;
      padding-right: $helfGutter-topThree-xxxs;
    }
  }
}

.topThreeTransition-enter-active,
.topThreeTransition-leave-active {
  transition: margin 0.5s $specialBezier 0s, opacity 0.5s $specialBezier 0s;
}

.topThreeTransition-enter,
.topThreeTransition-leave-to {
  margin-top: 180px;
  opacity: 0;
}

.topThreeTransition-enter-to,
.topThreeTransition-leave {
  margin-top: 0;
  opacity: 1;
}

.topThree__list {
  list-style-type: none;
  padding: 0;
}
.topThree__listItem {
  text-align: center;
  &:nth-child(1) {
    order: 2;
  }
  &:nth-child(2) {
    order: 1;
  }
  &:nth-child(3) {
    order: 3;
  }
  &:nth-child(2),
  &:nth-child(3) {
    .topThree__listItemImg {
      margin-top: 30px;
      width: 80%;
      padding-bottom: 80%;
    }
  }
  .topThree__listItemNameBr {
    display: none;
  }

  @media (max-width: $maxW-xxs) {
    .topThree__listItemNameBr {
      display: block;
    }
    .topThree__listItemNameSlash {
      display: none;
    }
  }
}
.topThree__listItemNum {
  line-height: 1;
  margin-bottom: 15px;
  font-size: 1rem;
  color: $color-yellow3;
  text-shadow: 2px 2px 8px rgb(0, 0, 0), 1px 1px 1px rgba(0, 0, 0, 0.25);
}
.topThree__listItemImg {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  margin: auto;
  background: {
    color: $color-gray;
    image: url(../assets/avatar-default.svg);
    position: center center;
    repeat: no-repeat;
    size: cover;
  }
  overflow: hidden;
  border-radius: 50%;
}
.topThree__listItemName {
  background-color: transparent;
  margin-top: 0.5rem;
  font-size: 0.7rem;
  line-height: 1.5;
  padding-left: 5px;
  padding-right: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

$barPaddingTopBottom: 3px;
$fz-listBar: 0.7rem;
.billboardSet__allList {
  padding: 10px;

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 10px;

    th,
    td {
      padding-top: $barPaddingTopBottom;
      padding-bottom: $barPaddingTopBottom;
      font-size: $fz-listBar;
      line-height: $fz-listBar;
      text-align: center;
      color: #f4ecd9;
      text-shadow: 0.7px 0.5px 0.3px #100b00;
    }
    th {
      white-space: nowrap;
      width: 1%;

      &:nth-child(1) {
        width: 1%;
        white-space: nowrap;
      }
      &:nth-child(2) {
        width: auto;
        text-align: left;
      }
    }
    td {
      width: 1%;
      white-space: nowrap;
      text-align: left;
    }
    .billboardSet__num {
      display: inline-block;
      padding: 5px 15px;
      background: {
        color: transparent;
        image: url(../assets/billboardListLink2.png);
        position: center 0;
        repeat: no-repeat;
        size: auto 100%;
      }
      margin-right: 15px;
    }
    .billboardSet__allListAvatar {
      display: inline-block;
      width: 80px;
      height: 80px;
      margin-right: 15px;
      border-radius: 50%;
      overflow: hidden;
    }
    td {
      position: relative;
      padding-left: 0;
    }
  }
  @media (max-width: $maxW-xxs) {
    padding: 0;
    table {
      border-spacing: 5px;
      .billboardSet__num {
        padding: 5px 10px;
        margin-right: 10px;
      }
      .billboardSet__allListAvatar {
        width: 60px;
        height: 60px;
        margin-right: 10px;
      }
    }
  }
  @media (max-width: $maxW-xxxs) {
    .billboardSet__rateDeco {
      display: none;
    }
  }
}
</style>
