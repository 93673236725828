<template>
  <div
    class="formDialog"
    :class="`formDialog--${site}`"
  >
    <div
      v-if="ready"
      class="wrapper"
    >
      <div
        v-if=" site==2 || site==3 || site==4 || site==6 || site==7"
        class="formDialogInner"
      >
        <header>
          <img
            v-if="site != 1"
            class="logo"
            alt="BS BET"
            width="132"
            height="57"
            :src="logoSrc"
          >
          <div
            v-if="site == 1"
            class="h2WithBG"
          >
            <h2>{{ terms.opinionTitle }}</h2>
          </div>
          <h2 v-else>
            {{ terms.opinionTitle }}
          </h2>
        </header>
        <p class="opinion__desc">
          我們非常在意您的感受及想法，希望您可以留下對本平台的建議或是如有遇到服務不佳的客服人員請您告訴我們，我們將用最快的速度改善！
        </p>
        <TheLoadingRoller
          v-show="sending"
          class="gradeForm__loading"
        />
        <div
          v-show="notice"
          class="gradeForm__notice"
        >
          <p>{{ notice }}</p>
          <button
            type="button"
            class="gradeForm__btn"
            @click="close"
          >
            {{ terms.ok }}
          </button>
        </div>
        <form v-show="!sending && !notice">
          <div class="gradeForm__item">
            <p class="gradeForm__itemP">
              {{ siteName + " " + terms.account
              }}<!-- GS BET 帳號 -->
            </p>
          </div>
          <input
            id="account"
            v-model.trim="acc"
            type="text"
            class="gradeForm__input"
          >
          <p
            v-show="showAccRequired"
            class="gradeForm__requiredHint"
            :class="shakeHint"
          >
            * {{ terms.required }}
          </p>

          <div class="gradeForm__item">
            <p class="gradeForm__itemP">
              {{ terms.opinionTime }}
            </p>
          </div>
          <input
            id="time"
            v-model.trim="opinionTime"
            type="text"
            class="gradeForm__input"
          >

          <div class="gradeForm__item">
            <p class="gradeForm__itemP">
              {{ terms.opinionClerk }}
            </p>
          </div>
          <input
            id="clerkName"
            v-model.trim="clerkName"
            type="text"
            class="gradeForm__input"
          >

          <div class="gradeForm__item">
            <p class="gradeForm__itemP">
              {{ terms.opinionDetail
              }}<!-- 給客服的建議 -->
            </p>
          </div>
          <textarea
            id="opinion"
            v-model.trim="opinion"
            name=""
            rows="4"
            class="gradeForm__textarea"
            :placeholder="terms.adviseHere"
          />
          <p
            v-show="showOpinionRequired"
            class="gradeForm__requiredHint"
            :class="shakeHint"
          >
            * {{ terms.required }}
          </p>
          <div class="gradeForm__btnBlock">
            <button
              type="button"
              class="gradeForm__btn gradeForm__btn--cancel"
              @click="cancel()"
            >
              {{ terms.cancel
              }}<!-- 取消評分 -->
            </button>
            <button
              type="button"
              class="gradeForm__btn gradeForm__btn--submit"
              @click="submit()"
            >
              {{ terms.submitRate
              }}<!-- 確認送出 -->
            </button>
          </div>
        </form>
      </div>
      <template v-else>
        No this page.
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { COOKIE_KIT } from '@/class/cookieKit'
import TheLoadingRoller from '@/components/TheLoadingRoller'
import { EventBus } from '@/event-bus'

export default {
  name: 'FormDialog',
  components: {
    TheLoadingRoller
  },
  data () {
    return {
      ready: false,
      acc: '',
      opinionTime: '',
      clerkName: '',
      opinion: '',
      sending: false,
      notice: '',
      shake: false,
      timeoutId: null,
      baseURL: ''
    }
  },
  computed: {
    showAccRequired: function () {
      return this.acc.trim() === ''
    },
    showOpinionRequired: function () {
      return this.opinion.trim() === ''
    },
    shakeHint: function () {
      return this.shake ? 'gradeForm__requiredHint--shake' : ''
    },
    cssIdx () {
      const idxs = [null, 1, 8, 2, 3, '4-1', 6, 7]
      return idxs[this.site]
    },
    logoSrc () {
      return `${this.baseURL}img/logo${this.cssIdx}.png`
    },
    ...mapState({
      uid: (state) => state.uid,
      site: (state) => state.site,
      terms: (state) => state.terms,
      siteName: (state) => state.siteName
    })
  },
  created: async function () {
    const LANGS = [null, 'en', 'tw', 'tw', 'tw', 'tw', 'tw', 'tw']
    try {
      const TERMS_RESPONSE = await this.$store.dispatch('getTexts')
      const TERMS = TERMS_RESPONSE.data
      const terms = TERMS[LANGS[this.site]]
      const siteName = TERMS['siteName' + this.site]
      if (this.site === 1) {
        this.site1Terms = {}
        this.site1Terms.en = TERMS.en
        this.site1Terms.cn = TERMS.cn
      }
      this.$store.commit('SET_TERMS', terms)
      const notices = TERMS.notices[LANGS[this.site]]
      this.$store.commit('SET_NOTICES', notices)
      this.$store.commit('SET_SITENAME', siteName)

      document.title = terms.clerkGrade + ' | ' + siteName

      this.ready = true
    } catch (err) {
      // EventBus.$emit('show-notice', { idx: 1, err: err.toString() })
    }
  },
  mounted: function () {
    this.baseURL = process.env.BASE_URL

    if (this.$store.state.gradeAcc) {
      this.acc = this.$store.state.gradeAcc
    } else {
      const acc = COOKIE_KIT.getCookie('rateAcc')
      if (acc) {
        this.acc = acc
        this.$store.commit('SET_RATE_ACC', acc)
      }
    }
  },
  methods: {
    stopShake: function () {
      this.shake = false
      this.timeoutId = null
    },
    cancel: function () {
      location.assign('./')
    },
    submit: async function () {
      if (this.showAccRequired) {
        this.shake = true
        if (this.timeoutId) {
          clearTimeout(this.timeoutId)
        }
        this.timeoutId = setTimeout(this.stopShake, 1100)
        return false
      }

      if (this.showOpinionRequired) {
        this.shake = true
        if (this.timeoutId) {
          clearTimeout(this.timeoutId)
        }
        this.timeoutId = setTimeout(this.stopShake, 1100)
        return false
      }

      const acc = this.acc.trim()
      const time = this.opinionTime.trim()
      const clerk = this.clerkName.trim()
      const opinion = this.opinion.trim()
      const opt = {}
      opt['max-age'] = 30 * 60 * 60 * 24
      COOKIE_KIT.setCookie('rateAcc', acc, opt)
      this.$store.commit('SET_RATE_ACC', acc)

      try {
        this.sending = true

        const response = await this.$store.dispatch('postOpinion', {
          site: this.site,
          account: acc,
          time,
          clerk,
          opinion
        })
        this.sending = false

        const snap = response.data * 1
        const notices = [
          this.terms.opinionPostSuccess, // 意見回報成功。
          this.terms.opinionPostSiteFail, // 回報失敗（error code:1）
          this.terms.opinionPostAccFail, // 回報失敗（error code:2）
          this.terms.opinionPostOpinionFail, // 回報失敗（error code:3）
          this.terms.opinionPostTimeFail, // 回報失敗（error code:4）
          this.terms.opinionPostClerkFail, // 回報失敗（error code:5）
          this.terms.opinionPostOtherFail // 回報失敗（error code:6）
        ]
        this.notice = notices[snap]
      } catch (err) {
        EventBus.$emit('show-notice', { idx: 5, err: err.toString() })
      }
    },
    close () {
      if (this.notice === this.terms.opinionPostSuccess) {
        this.opinionTime = ''
        this.clerkName = ''
        this.opinion = ''
      }
      this.notice = ''
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../scss/vars";
@import "../scss/mixins";

body{height: 100%;}

.formDialog {
  width: 100%;
  min-height: 100%;
}

.wrapper {
  width: 100%;
  max-width: 500px;
  min-height: 100vh;
  margin: auto;
  padding: 0 ;
  background: {
    color: transparent;
    image: url(../assets/bg-wrapper5.jpg);
    position: center 0;
    repeat: no-repeat;
    size: cover;
  }
}

h2 {
  font-size: 0.8rem;
  padding-top: 6px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.formDialogInner {
  padding: $pt-page 20px;

  header .avatarImg {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
  }

  @media (max-width: $maxW-xxs) {
    padding: $pt-page 12px 20px;
  }
}

.logo {
  width: 200px;
  height: 57px;
  margin-top: 0;
  margin-bottom: 0;
  vertical-align: top;
}

.gradeForm__loading {
  margin-top: 20%;
}
.gradeForm__notice {
  font-size: 1.25rem;
  text-shadow: 1px 0 0 $color-black, 0 1px 0 $color-black,
    1px 1px 0 $color-black;
}
.gradeForm__gradeFieldset {
  display: flex;
  border: 1px solid $color-gold;
  background-color: $color-black;
  flex-flow: row nowrap;
  justify-content: space-around;
  padding-bottom: 10px;
}
.gradeForm__gradeLegend {
  display: none;
}
.gradeForm__gradeCol {
  width: 19%;
  overflow: visible;

  .gradeForm__gradeDeco {
    font-size: 0;
    height: 2px;
    width: 80%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 10%;
    margin-right: 10%;
  }

  &:nth-child(2) {
    .gradeForm__gradeDeco {
      background-color: #9b120b;
    }
  }
  &:nth-child(3) {
    .gradeForm__gradeDeco {
      background-color: #cc5b1d;
    }
  }
  &:nth-child(4) {
    .gradeForm__gradeDeco {
      background-color: #fdc75f;
    }
  }
  &:nth-child(5) {
    .gradeForm__gradeDeco {
      background-color: #90a82c;
    }
  }
  &:nth-child(6) {
    .gradeForm__gradeDeco {
      background-color: #54b74b;
    }
  }
  @media (max-width: $maxW-xxxs) {
    .gradeForm__gradeDeco {
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
    }
  }
}
.gradeForm__gradeLabel {
  display: block;
  margin-bottom: 0;
  margin-top: -20px;
  opacity: 0.5;
}

.gradeForm__gradeRadie {
  display: inline-block;
  overflow: hidden;
  width: 0;
  height: 0;
  opacity: 0;
}
.gradeForm__gradeRadie:checked + .gradeForm__gradeLabel {
  opacity: 1;
}
.gradeForm__gradeDesc,
.gradeForm__gradePoint {
  font-size: 0.6rem;
  margin-bottom: 0;
}
.gradeForm__gradeDesc {
  transform: scale(0.8);
  white-space: nowrap;

  @media (min-width: $minW-sm) {
    transform: scale(1);
  }
}
.gradeForm__gradeImg {
  max-width: 90%;
}
.gradeForm__item {
  text-align: left;
}
.gradeForm__itemP {
  display: inline-block;
  font-size: 0.7rem;
  background-color: #644e1f;
  text-align: left;
  margin-left: 0;
  margin-top: 10px;
  margin-bottom: 0;
  padding: 4px 10px;
  line-height: 1;
}

.gradeForm__input,
.gradeForm__textarea {
  width: 100%;
  border: 1px solid $color-gold;
  background-color: $color-black;
  color: $color-white;
  font-size: 0.8rem;
}
.gradeForm__input {
  padding-left: 5px;
  padding-right: 5px;
  line-height: 1.8;
}
.gradeForm__requiredHint {
  font-size: 0.7rem;
  line-height: 1;
  margin-bottom: 15px;
  color: $color-red;
  text-align: left;
}

@keyframes shakeHint {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(-7px);
  }
  50% {
    transform: translate(0);
  }
  75% {
    transform: translate(7px);
  }
  100% {
    transform: translate(0);
  }
}
.gradeForm__requiredHint--shake {
  animation: shakeHint 0.2s ease-in-out 0s infinite normal none;
}

.gradeForm__textarea {
  resize: none;
  padding: 5px;
  line-height: 1.2;
}
$w-formBtn: 118px;
$h-formBtn: 26px;
.gradeForm__btn {
  color: $color-white;
  width: $w-formBtn;
  height: $h-formBtn;
  font-weight: 500;
  background: {
    color: $color-gold;
    image: url(../assets/game-bt.jpg);
    position: center center;
    repeat: no-repeat;
    size: cover;
  }
  font-size: 0.9rem;
  line-height: 1;
  border: none;
  text-shadow: 1px 1px 1px $color-black, 1px 1px 1px $color-black,
    1px 1px 2px $color-black;

  &.gradeForm__btn--cancel {
    background: {
      color: $color-gray;
      image: url(../assets/game-bt2.jpg);
    }
  }
  &.gradeForm__btn--submit {
    margin-left: 5px;
  }
}

::placeholder {
  color: $color-gray;
}

.formDialog--1 {
  .h2WithBG {
    position: relative;
    width: 80%;
    height: 0;
    padding-bottom: 38%;
    margin: auto;
    margin-bottom: 20px;
    background: {
      image: url(../assets/logo5.png);
      position: center center;
      repeat: no-repeat;
      size: contain;
    }

    h2 {
      position: absolute;
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 0;
      margin-bottom: 0;
      width: 100%;
      top: 50%;
      text-align: center;
      font-size: 1.1rem;
      text-shadow: 1px -1px 1px $color-purple-site2,
        -1px -1px 1px $color-purple-site2, -1px 1px 1px $color-purple-site2,
        1px 1px 1px $color-purple-site2;
    }
  }
  .avatarImg {
    margin-bottom: 20px;
    box-shadow: 5px 5px 0px #ac15ea;
  }
  .gradeForm__gradeFieldset,
  .gradeForm__input,
  .gradeForm__textarea {
    border: 1px solid #6fa4f7;
    background: linear-gradient(to bottom, #c842db3d 10%, #00000005 60%);

    &::placeholder {
      color: darken($color-white, 10%);
    }
  }
  .gradeForm__itemP {
    background: linear-gradient(to bottom, #fb4200 15%, #84182d 60%);
    border-radius: 5px;
    box-shadow: 0px 2px 0px #000000;
  }
  .gradeForm__btn {
    background: {
      color: transparent;
      image: url(../assets/gbet-btn2.png);
    }
    height: 59px;
    padding-bottom: 2%;

    &.gradeForm__btn--cancel {
      background: {
        color: transparent;
        image: url(../assets/gbet-btn3.png);
      }
    }
  }
}
.formDialog--2 {
  .wrapper {
    background-image: url(../assets/bg-site-2-new.jpg);
  }

  h2 {
    font-size: 1.2rem;
    filter: drop-shadow(0px 0px 2px #dd2222) drop-shadow(0px 0px 3px #dd2222);
  }

  .avatarImg {
    margin-bottom: 20px;
    box-shadow: 5px 5px 0px #ac15ea;
  }
  .gradeForm__gradeFieldset,
  .gradeForm__input,
  .gradeForm__textarea {
    border: 2px solid #ffffff80;
    background: linear-gradient(180deg, #1100ee33, rgba(14, 29, 74, 0.2));

    &::placeholder {
      color: darken($color-white, 10%);
    }
  }
  .gradeForm__itemP {
    background: none;
    border-radius: 0;
    box-shadow: none;
    padding-left: 0;
    font-size: 0.9rem;
    filter: drop-shadow(0px 0px 2px #dd2222) drop-shadow(0px 0px 3px #dd2222);
  }
  .gradeForm__btn {
    border-radius: 4px;
    background: {
      color: #07f;
      image: none;
    }
    height: 40px;
    padding-bottom: 3px;

    &.gradeForm__btn--cancel {
      background: {
        color: #999;
        image: none;
      }
    }
  }
}

.formDialog--3 {
  .wrapper {
    background-image: url(../assets/bg-wrapper2.jpg);
  }
  .formDialogInner {
    background: {
      color: transparent;
      image: url(../assets/bg-billboard2.png);
      position: center 0;
      repeat: no-repeat;
      size: 100% auto;
    }
  }
  .logo {
    width: 280px;
    height: auto;
  }

  .gradeForm__gradeFieldset {
    border: none;
    background-color: transparent;
  }
  .gradeForm__item {
    text-align: center;
  }
  .gradeForm__itemP {
    color: #f4ecd9;
    text-shadow: 0.7px 0.5px 0.3px #100b00;
    background: {
      color: transparent;
      image: url(../assets/billboardListLink2.png);
      position: center center;
      repeat: no-repeat;
      size: cover;
    }
  }
  .gradeForm__input,
  .gradeForm__textarea {
    margin-top: 0.2rem;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-image: linear-gradient(
        to left,
        $color-gold,
        rgba(0, 0, 0, 0),
        $color-gold
      )
      1/1px;
  }

  .gradeForm__btn {
    background: {
      color: transparent;
      image: url(../assets/billboardListLink2.png);
      position: center center;
      repeat: no-repeat;
      size: cover;
    }
    text-shadow: none;

    &.gradeForm__btn--cancel {
      background: {
        color: transparent;
        image: url(../assets/billboardListLink2.png);
      }
    }
  }
  ::placeholder {
    color: #7f7769;
  }
}
.formDialog--4 {
  .wrapper {
    background-image: url(../assets/bg-wrapper3.jpg);
  }

  .gradeForm__gradeFieldset {
    border: 1px solid $color-lightOrange;
  }

  .gradeForm__itemP {
    color: $color-black;
    background-color: $color-lightOrange;
  }
  .gradeForm__input,
  .gradeForm__textarea {
    border: 1px solid $color-lightOrange;
  }

  .gradeForm__btn {
    background: {
      color: transparent;
      image: url(../assets/btn-3.jpg);
      position: center center;
      repeat: no-repeat;
      size: cover;
    }

    &.gradeForm__btn--cancel {
      background: {
        color: transparent;
        image: url(../assets/btn-3.jpg);
      }
    }
  }
}

.formDialog--5 {
  .wrapper {
    background-image: url(../assets/bg-wrapper4.jpg);
  }
  .formDialogInner {
    padding: 40px;
  }
  .logo {
    width: 220px;
    height: auto;
  }
  h2 {
    font-size: 0.9rem;
    font-weight: bold;
    text-shadow: 1px 1px 2px lighten($color-blue-5, 10%),
      -1px -1px 2px lighten($color-blue-5, 10%),
      -1px 1px 2px lighten($color-blue-5, 10%),
      1px -1px 2px lighten($color-blue-5, 10%);
  }
  .gradeForm__gradeFieldset {
    border: none;
    background-color: transparent;
  }
  .gradeForm__gradePoint {
    font-weight: bold;
    text-shadow: 1px 1px 2px lighten($color-blue-5, 10%),
      -1px -1px 2px lighten($color-blue-5, 10%),
      -1px 1px 2px lighten($color-blue-5, 10%),
      1px -1px 2px lighten($color-blue-5, 10%);
    font-size: 0.6rem;
  }
  .gradeForm__gradeDesc {
    transform: scale(1);
  }
  .gradeForm__gradeImg {
    max-width: 80%;
  }
  .gradeForm__item {
    text-align: center;
    margin-bottom: 5px;
  }
  .gradeForm__itemP {
    background-color: transparent;
    font-weight: bold;
    font-size: 0.8rem;
    text-shadow: 1px 1px 2px lighten($color-blue-5, 10%),
      -1px -1px 2px lighten($color-blue-5, 10%),
      -1px 1px 2px lighten($color-blue-5, 10%),
      1px -1px 2px lighten($color-blue-5, 10%);
  }
  .gradeForm__input,
  .gradeForm__textarea {
    border: 1px solid $color-white;
    box-shadow: 0 0 3px 2px lighten($color-blue-5, 10%);
  }

  .gradeForm__btnBlock {
    margin-top: 20px;
  }
  .gradeForm__btn {
    background: {
      color: transparent;
      image: url(../assets/btn-4.jpg);
      position: center center;
      repeat: no-repeat;
      size: cover;
    }

    &.gradeForm__btn--cancel {
      background: {
        color: transparent;
        image: url(../assets/btn-4.jpg);
      }
    }
  }

  @media (max-width: $maxW-xxs) {
    .formDialogInner {
      padding: 20px;
    }
    .gradeForm__gradeImg {
      max-width: 90%;
    }
  }
  @media (max-width: $maxW-xxxs) {
    .gradeForm__gradeDesc {
      transform: scale(0.8);
    }
  }
}
.formDialog--6 {
  .wrapper {
    background-image: url(../assets/bg-wrapper6.jpg);
  }
  .gradeForm__gradeFieldset {
    border-color: $color-purple-site6;
    background-color: $semi-black-site6;
  }
  .gradeForm__itemP {
    background-color: $color-darkpurple-site6;
  }
  .gradeForm__input,
  .gradeForm__textarea {
    border-color: $color-purple-site6;
  }

  .gradeForm__btn {
    border-radius: 3px;
    background: {
      color: $color-green-site6;
      image: none;
    }
    &.gradeForm__btn--cancel {
      color: $color-gray;
      background: {
        color: $color-darkpurple-site6;
        image: none;
      }
    }
    &.gradeForm__btn--submit {
    }
  }
}
.formDialog--7 {
  .wrapper {
    background-image: url(../assets/bg-wrapper7.jpg);
  }
  .gradeForm__gradeFieldset {
    border-color: $color-blue-site7;
  }
  .gradeForm__itemP {
    background-color: $color-blue-site7;
  }
  .gradeForm__input,
  .gradeForm__textarea {
    border-color: $color-blue-site7;
  }

  .gradeForm__btn {
    border-radius: 3px;
    background: {
      color: $color-green-site7;
      image: none;
    }
    &.gradeForm__btn--cancel {
      color: $color-lightBlue;
      background: {
        color: $color-blue-site7;
        image: none;
      }
    }
    &.gradeForm__btn--submit {
    }
  }
}

.opinion__desc{
  text-align: left;
  font-size: 0.8rem;
  margin-top: 20px;

  @media (max-width: $maxW-xxxs) {
    font-size: 0.6rem;
  }
}
</style>
