import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Privacy from '../views/Privacy.vue'
import Opinion from '../views/Opinion.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: process.env.BASE_URL,
    name: 'Home',
    component: Home
  },
  {
    path: process.env.BASE_URL + 'privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: process.env.BASE_URL + 'opinion',
    name: 'Opinion',
    component: Opinion
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
