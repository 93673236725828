import { mapState } from 'vuex'
import { COOKIE_KIT } from '@/class/cookieKit'
import { EventBus } from '@/event-bus'

export default {
  name: 'AllMembers',
  props: {
    authState: { type: String, required: true }
  },
  data () {
    return {
      donateMode: false
    }
  },
  computed: {
    ...mapState({
      allList: state => state.allMembers.data,
      uid: state => state.uid,
      site: state => state.site,
      terms: state => state.terms
    })
  },
  created () {
    if (this.site === 1 && !window.FB) {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: 1030877374095465,
          cookie: true,
          xfbml: true,
          version: 'v9.0'
        })

        window.FB.AppEvents.logPageView()
      };

      (function (d, s, id) {
        var js
        var fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) {
          return
        }
        js = d.createElement(s)
        js.id = id
        js.src = 'https://connect.facebook.net/en_US/sdk.js'
        fjs.parentNode.insertBefore(js, fjs)
      })(document, 'script', 'facebook-jssdk')
    }
  },
  methods: {
    imgStyle (img) {
      return img ? `background-image:url(${img})` : ''
    },
    checkStoreUid (idx) {
      if (this.uid != null) {
        this.showDialog(idx)
      } else {
        this.getProfile(idx)
      }
    },
    checkCookieToken (idx, donateMode) {
      this.donateMode = !!donateMode
      if (this.site === 5) {
        this.$store.commit('SET_UID', '')
        this.showDialog(idx)
      } else if (this.site === 1) {
        if (this.$store.state.fbToken && this.uid) {
          this.showDialog(idx)
        } else {
          const vm = this
          window.FB.getLoginStatus(function (response) {
            if (response.status === 'connected') {
              // The user is logged in and has authenticated your
              // app, and response.authResponse supplies
              // the user's ID, a valid access token, a signed
              // request, and the time the access token
              // and signed request each expire.
              vm.$store.commit('SET_UID', response.authResponse.userID)
              vm.$store.commit(
                'SET_FB_TOKEN',
                response.authResponse.accessToken
              )
              vm.showDialog(idx)
            } else {
              EventBus.$emit('show-fb-login')
            }
            // window.FB.logout(function (response) { })
          })
        }
      } else {
        const token = COOKIE_KIT.getCookie('la_token')
        if (token) {
          this.checkStoreUid(idx)
        } else {
          this.lineAuth()
        }
      }
    },
    lineAuth () {
      const infos = [
        null,
        null, // 第一站
        {
          // 第二站
          cid: 1655233404,
          callback: 'https%3A%2F%2Fgbet.tw%2F'
        },
        {
          // 第三站
          cid: 1655233429,
          callback: 'https%3A%2F%2Fpkg365.cc%2F'
        },
        {
          // 第四站
          cid: 1655233434,
          callback: 'https%3A%2F%2Flottery.wine%2F'
        },
        null, // 第五站,
        {
          // 第六站
          cid: 1657067249,
          callback: 'https%3A%2F%2Ffeida365.tw%2F'
        },
        {
          // 第七站
          cid: 1655196939,
          callback: 'https%3A%2F%2Fyggaming.tw%2F'
        }
      ]
      const cid = infos[this.site].cid
      let url = 'https://access.line.me/oauth2/v2.1/authorize?'
      url += 'response_type=code'
      url += '&client_id=' + cid
      url += '&redirect_uri=' + infos[this.site].callback
      url += '&state=' + this.authState
      url += '&scope=openid%20profile'
      // url += '&nonce=danny'
      window.location.href = url
    },
    getProfile: async function (idx) {
      try {
        const response = await this.$store.dispatch('getProfile')
        this.$store.commit('SET_UID', response.data.userId)
        this.showDialog(idx)
      } catch (err) {
        COOKIE_KIT.deleteCookie('la_token')
        EventBus.$emit('show-notice', { idx: 2, err: err.toString() })
      }
    },
    showDialog (idx) {
      if (this.site !== 5 && !this.uid) {
        EventBus.$emit('show-notice', { idx: 2, err: '缺 uid' })
        return
      }
      if (this.donateMode) {
        this.showDonateDialog(idx)
      } else {
        this.showRatingDialog(idx)
      }
    },
    showDonateDialog (idx) {
      const curr = this.allList[idx]
      this.$store.commit('DONATE_TO', {
        mid: curr.mid,
        name: curr.name,
        img: curr.img
      })
      this.$store.commit('FreezeBody', true)
    },
    showRatingDialog (idx) {
      const curr = this.allList[idx]
      this.$store.commit('RATE_TO', {
        mid: curr.mid,
        name: curr.name,
        img: curr.img
      })
      this.$store.commit('FreezeBody', true)
    }
  }
}
