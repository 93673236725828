<template>
  <div class="wrapper homeWrapper--1">
    <section class="homeSection">
      <div class="homeSection__inner">
        <header class="firstHeader">
          <div class="h2WithBG">
            <h2 v-if="terms">
              {{ terms.privacy
              }}<!-- 隱私權政策 -->
            </h2>
          </div>
          <div class="langSwitcher">
            <button
              type="button"
              class="langSwitcher__btn"
              @click="swithLang('cn')"
            >
              簡中
            </button>|
            <button
              type="button"
              class="langSwitcher__btn"
              @click="swithLang('en')"
            >
              EN
            </button>
          </div>
        </header>
        <PrivacyCN v-if="lang == 'cn'" />
        <PrivacyEN v-if="lang == 'en'" />
      </div>
      <div class="linkBar">
        <router-link :to="{ name: 'Home' }">
          {{ terms.backToHome }}
        </router-link>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import { EventBus } from '@/event-bus'
import PrivacyEN from '@/components/Privacy_en'
import PrivacyCN from '@/components/Privacy_cn'

export default {
  name: 'Privacy',
  components: {
    PrivacyEN,
    PrivacyCN
  },
  data () {
    return {
      ready: false,
      baseURL: '',
      site1Terms: null,
      lang: ''
    }
  },
  computed: {
    cssIdx () {
      const idxs = [null, 1, 1, 2, 3, 4]
      return idxs[this.site]
    },
    logoSrc () {
      return `${this.baseURL}img/logo${this.cssIdx}.png`
    },
    ...mapState({
      site: (state) => state.site,
      terms: (state) => state.terms
    })
  },
  created: async function () {
    const LANGS = [null, 'en', 'tw', 'tw', 'tw', 'tw', 'tw', 'tw']
    try {
      const TERMS_RESPONSE = await this.$store.dispatch('getTexts')
      const TERMS = TERMS_RESPONSE.data
      this.lang = LANGS[this.site]
      const terms = TERMS[this.lang]
      const siteName = TERMS['siteName' + this.site]
      if (this.site === 1) {
        this.site1Terms = {}
        this.site1Terms.en = TERMS.en
        this.site1Terms.cn = TERMS.cn
      }
      this.$store.commit('SET_TERMS', terms)
      const notices = TERMS.notices[LANGS[this.site]]
      this.$store.commit('SET_NOTICES', notices)
      this.$store.commit('SET_SITENAME', siteName)

      document.title = terms.clerkGrade + ' | ' + siteName
    } catch (err) {
      EventBus.$emit('show-notice', { idx: 1, err: err.toString() })
    }
  },
  mounted () {
    this.baseURL = process.env.BASE_URL
  },
  methods: {
    swithLang (lang) {
      this.lang = lang
      this.$store.commit('SET_TERMS', this.site1Terms[lang])
    }
  }
}
</script>

<style lang="scss">
@import "../scss/vars";

.wrapper {
  width: 100%;
  max-width: 500px;
  min-height: 100%;
  margin: auto;
  padding: 0;

  .homeSection {
    padding: $pt-page 20px 20px;
    background: {
      color: transparent;
      image: url(../assets/bg-wrapper5.jpg);
      position: center 0;
      repeat: repeat-y;
      size: 100% auto;
    }
  }
  .billboard__tabContent {
    margin-top: 1rem;
  }

  @media (max-width: $maxW-xxs) {
    .homeSection {
      padding: $pt-page 12px 20px;
    }
  }
}
.firstHeader {
  position: relative;

  .langSwitcher {
    position: absolute;
    right: 0;
    top: 0;
  }

  .langSwitcher__btn {
    background-color: transparent;
    border: none;
    text-decoration: underline;
    color: $color-white;
    font-size: 0.8rem;
  }
}

.logo {
  width: 200px;
  height: 57px;
  margin-bottom: 0;
}

h2 {
  font-size: 0.8rem;
  padding-top: 6px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.goldBorder {
  border: 1px solid $color-gold;
}

.wrapper.homeWrapper--1,
.wrapper.homeWrapper--2 {
  .h2WithBG {
    position: relative;
    width: 80%;
    height: 0;
    padding-bottom: 38%;
    margin: auto;
    background: {
      image: url(../assets/logo5.png);
      position: center center;
      repeat: no-repeat;
      size: contain;
    }

    h2 {
      position: absolute;
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 0;
      margin-bottom: 0;
      width: 100%;
      top: 53%;
      text-align: center;
      font-size: 1.1rem;
      text-shadow: 1px -1px 1px $color-purple-site2,
        -1px -1px 1px $color-purple-site2, -1px 1px 1px $color-purple-site2,
        1px 1px 1px $color-purple-site2;
    }
  }
}
.wrapper.homeWrapper--3 {
  .homeSection {
    padding: 0 0 20px;
    background-image: url(../assets/bg-wrapper2.jpg);
  }
  .homeSection__inner {
    padding: $pt-page 20px 20px;
    background: {
      color: transparent;
      image: url(../assets/bg-billboard2.png);
      position: center 0;
      repeat: no-repeat;
      size: 100% auto;
    }
  }
  h2 {
    text-shadow: 1px -1px 1px #a2851a, -1px -1px 1px #a2851a,
      -1px 1px 1px #a2851a, 1px 1px 1px #a2851a;
  }
  .logo {
    width: 280px;
    height: auto;
  }
}
.wrapper.homeWrapper--4 {
  .homeSection {
    background-image: url(../assets/bg-wrapper3.jpg);
  }
}
.wrapper.homeWrapper--5 {
  /* font-family: "Noto Sans SC", "Microsoft JhengHei", "微軟正黑體", Avenir,
    Helvetica, Arial, sans-serif; */

  .homeSection {
    background-image: url(../assets/bg-wrapper4.jpg);

    h2 {
      display: block;
      line-height: 2;
      text-indent: -9999px;
      margin-bottom: 30px;
      background: {
        color: transparent;
        image: url(../assets/service_investigate5.png);
        position: center 0;
        repeat: no-repeat;
        size: 80% auto;
      }
    }
    &:nth-child(2) {
      h2 {
        margin-bottom: 15px;
        background: {
          image: url(../assets/service_rank5.png);
          size: 65% auto;
        }
      }
    }
  }
  .logo {
    width: 70px;
    height: auto;
    margin-top: 30px;
  }

  .billboard__tabContent {
    margin-top: 1.5rem;
  }
}
.wrapper.homeWrapper--1 {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
.linkBar {
  font-size: 0.75rem;
  text-align: right;
  a {
    color: $color-white;
    text-decoration: underline;
  }
}
</style>
