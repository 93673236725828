<template>
  <div
    class="billboardSet"
    :class="`billboardSet--${site}`"
  >
    <div class="topThree container-fluid">
      <div
        v-if="loadStatus == 1"
        class="row"
      >
        <TheLoadingRoller class="billboardSet__loading" />
      </div>
      <p
        v-else-if="top3.length == 0"
        class="topThree__noData"
      >
        暫無資料
      </p>
      <transition name="topThreeTransition">
        <ul
          v-if="loadStatus == 2 && top3.length > 0"
          class="topThree__list row"
        >
          <li
            v-for="(item, billbordIdx) in top3"
            :key="item.mid"
            class="topThree__listItem col col-4"
          >
            <p class="topThree__listItemNum">
              NO.{{ billbordIdx * 1 + 1 }}
            </p>
            <div
              class="topThree__listItemImg"
              :style="billboardImgStyle(item.img)"
            />
            <p class="topThree__listItemName">
              {{ item.name + " / " + item.sum }}
            </p>
          </li>
        </ul>
      </transition>
    </div>
    <div
      v-if="loadStatus == 2 && all.length > 0"
      class="billboardSet__allList"
    >
      <table>
        <tr
          v-for="(item, billbordIdx) in all"
          :key="item.mid"
        >
          <th>
            <div class="billboardSet__allListAvatarWrapper">
              <div
                class="billboardSet__allListAvatar"
                :style="billboardImgStyle(item.img)"
              />
            </div>
          </th>
          <td>
            <div>
              <span
                class="billboardSet__num"
              >NO.{{ billbordIdx * 1 + 4 }}</span>
              <span>{{
                `${item.name} / ${terms.currentSum}: ${item.sum}`
              }}</span>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import billboardMixin from '@/mixins/billboardMixin'

export default {
  mixins: [billboardMixin],
  computed: {
    ...mapState({
      all: function (state) {
        return this.currData &&
          this.currData.data &&
          this.currData.data.length > 3
          ? this.currData.data.slice(3)
          : []
      }
    })
  }
}
</script>
<style lang="scss" scoped>
@import "../scss/vars";
$helfGutter-topThree: 10px;

.billboardSet__loading {
  margin: auto;
  margin: 10% auto;
}

.topThree__noData {
  padding: 80px 0;
}

.topThree.container-fluid {
  padding: 0;

  .row {
    margin-right: -$helfGutter-topThree;
    margin-left: -$helfGutter-topThree;
  }
  .col {
    padding-left: $helfGutter-topThree;
    padding-right: $helfGutter-topThree;
  }
}

.topThreeTransition-enter-active,
.topThreeTransition-leave-active {
  transition: margin 0.5s $specialBezier 0s, opacity 0.5s $specialBezier 0s;
}

.topThreeTransition-enter,
.topThreeTransition-leave-to {
  margin-top: 180px;
  opacity: 0;
}

.topThreeTransition-enter-to,
.topThreeTransition-leave {
  margin-top: 0;
  opacity: 1;
}

.topThree__list {
  list-style-type: none;
  padding: 0;
}
.topThree__listItem {
  text-align: center;
  &:nth-child(1) {
    order: 2;

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 40px;
      height: 40px;
      right: 20px;
      top: 20px;
      transform: rotate(20deg);
      z-index: 100;
      filter: drop-shadow(0px 0px 2px #dd2222) drop-shadow(0px 0px 3px #dd2222);
      background: {
        color: transparent;
        image: url(../assets/icon-crown-2.svg);
        position: center center;
        repeat: no-repeat;
        size: 100% 100%;
      }
    }
  }
  &:nth-child(2) {
    order: 1;
  }
  &:nth-child(3) {
    order: 3;
  }
  &:nth-child(2),
  &:nth-child(3) {
    transform: scale(0.8);
  }
}
.topThree__listItemNum {
  line-height: 1;
  margin-bottom: 15px;
  font-size: 1rem;
  color: $color-white;
  filter: drop-shadow(0px 0px 2px #dd2222) drop-shadow(0px 0px 3px #dd2222);
}
.topThree__listItemImg {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background: {
    color: $color-gray;
    image: url(../assets/avatar-default.svg);
    position: center center;
    repeat: no-repeat;
    size: cover;
  }
  overflow: hidden;
  border-radius: 50%;
  box-shadow: none;
  filter: drop-shadow(3px 3px 2px #dd2222) drop-shadow(3px 3px 3px #dd2222);
}
.topThree__listItemName {
  margin-top: 1.2rem;
  font-size: 0.7rem;
  line-height: 1.5;
  padding-left: 5px;
  padding-right: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  filter: drop-shadow(0px 0px 2px #dd2222) drop-shadow(0px 0px 3px #dd2222);

  @media (max-width: $maxW-xxxs) {
    font-size: 0.6rem;
    letter-spacing: -1px;
  }
}
.topThree__listItem {
  &:nth-child(2),
  &:nth-child(3) {
    .topThree__listItemName {
      transform: scale(1.25);
    }
  }
}

$barPaddingTopBottom: 3px;
$fz-listBar: 0.8rem;
.billboardSet__allList {
  position: relative;
  border: 2px solid #ffffff80;
  background: linear-gradient(180deg, #1100ee33, rgba(14, 29, 74, 0.2));
  padding: 10px 20px;

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 10px;

    th,
    td {
      padding-top: $barPaddingTopBottom;
      padding-bottom: $barPaddingTopBottom;
      font-size: $fz-listBar;
      line-height: $fz-listBar;
      text-align: center;
      color: #f4ecd9;
    }
    th {
      white-space: nowrap;
      width: 1%;

      &:nth-child(1) {
        width: 1%;
        white-space: nowrap;
      }
    }
    td {
      text-align: left;
      vertical-align: center;
      font-weight: bold;
      padding-left: 20px;

      span {
        position: relative;
        z-index: 3;
        filter: drop-shadow(0px 0px 2px #0077ff) drop-shadow(0px 0px 3px #0077ff);
      }
      div {
        // display: inline-block;
        position: relative;
        height: 100%;
        // background: linear-gradient(to bottom, #6666d000 0%, #6666d0 30%);
        border-radius: 10px;
      }
    }
    .billboardSet__num {
      display: inline-block;
      text-align: left;
      width: 50px;
      padding: 5px 0;
      margin-right: 15px;
    }
    .billboardSet__allListAvatarWrapper {
      display: inline-block;
      position: relative;
      width: 40px;
      height: 40px;
      margin: auto;
      overflow: hidden;
      border-radius: 50%;
      box-shadow: none;
      filter: drop-shadow(2px 2px 2px #0077ff) drop-shadow(2px 2px 3px #0077ff);
    }
    .billboardSet__allListAvatar {
      position: absolute;
      left: -7%;
      right: -7%;
      top: 0;
      bottom: 0;
      margin: auto;
      background: {
        color: $color-gray;
        image: url(../assets/avatar-default.svg);
        position: center center;
        repeat: no-repeat;
        size: cover;
      }
    }
  }
  @media (max-width: $maxW-xxs) {
    padding:  10px ;
    table {
      border-spacing: 5px;
      td {
        font-size: 0.7rem;
      }
      .billboardSet__num {
        padding: 5px 10px;
        margin-right: 10px;
      }
      .billboardSet__allListAvatarSet {
        width: 40px;
        height: 40px;
      }
    }
  }
  @media (max-width: $maxW-xxxs) {
    &:after {
      left: -5px;
      bottom: -13px;
      width: 32px;
      height: 40px;
    }
    table {
      td {
        font-size: 0.6rem;
      }
      .billboardSet__num {
        padding: 5px 0 5px 10px;
        margin-right: 0;
      }
    }
    .billboardSet__rateDeco {
      display: none;
    }
  }
}
</style>
