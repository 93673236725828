<template>
  <div
    class="wrapper"
    :class="`homeWrapper--${site}`"
  >
    <div class="hiddenSvg">
      <svg
        id="圖層_1"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 30 30"
        style="enable-background:new 0 0 30 30;"
        xml:space="preserve"
      >
        <g
          id="message"
        >
          <path
            class="st0"
            d="M29.2,1.3C29.8,1.7,30.1,2.4,30,3l-3.7,23.6c-0.1,0.6-0.4,1.1-0.9,1.3s-1.1,0.3-1.5,0.1l-6.8-2.8l-3.9,4.2c-0.5,0.6-1.3,0.8-2,0.5c-0.7-0.3-1.2-1-1.2-1.7v-4.7c0-0.2,0.1-0.5,0.2-0.6l9.5-10.4c0.3-0.4,0.3-0.9,0-1.3c-0.4-0.4-0.9-0.4-1.3,0L7,21.4l-5-2.5c-0.6-0.3-1-0.9-1-1.5s0.3-1.3,0.9-1.6L27.3,1.2C27.9,0.9,28.6,0.9,29.2,1.3L29.2,1.3z"
          />
          <path
            class="st1"
            d="M28.2,0.3C28.8,0.7,29.1,1.4,29,2l-3.7,23.6c-0.1,0.6-0.4,1.1-0.9,1.3s-1.1,0.3-1.5,0.1l-6.8-2.8l-3.9,4.2c-0.5,0.6-1.3,0.8-2,0.5c-0.7-0.3-1.2-1-1.2-1.7v-4.7c0-0.2,0.1-0.5,0.2-0.6l9.5-10.4c0.3-0.4,0.3-0.9,0-1.3c-0.4-0.4-0.9-0.4-1.3,0L6,20.4l-5-2.5c-0.6-0.3-1-0.9-1-1.5c0-0.7,0.3-1.3,0.9-1.6L26.3,0.2C26.9-0.1,27.6-0.1,28.2,0.3L28.2,0.3z"
          />
        </g>
      </svg>
    </div>
    <section
      v-if="ready && site"
      id="billboard"
      class="homeSection"
    >
      <div class="homeSection__inner">
        <header class="firstHeader">
          <img
            v-if="site != 1"
            class="logo"
            alt="BS BET"
            width="132"
            height="57"
            :src="logoSrc"
          >
          <div
            v-if="site == 1"
            class="h2WithBG"
          >
            <h2>
              {{ terms.billboard
              }}<!-- 客服排行榜 -->
            </h2>
          </div>
          <h2 v-else>
            {{ terms.billboard
            }}<!-- 客服排行榜 -->
          </h2>
          <div
            class="headerBar"
          >
            <template v-if="site == 1">
              <button
                type="button"
                class="langSwitcher__btn"
                @click="swithLang('cn')"
              >
                簡中
              </button>|
              <button
                type="button"
                class="langSwitcher__btn"
                @click="swithLang('en')"
              >
                EN
              </button>
            </template>
            <routerLink
              class="opinionLink"
              :to="{name:'Opinion'}"
            >
              <svg
                viewBox="0 0 30 30"
                xmlns="http://www.w3.org/2000/svg"
              >
                <use
                  class="opinion__linkIcon"
                  href="#message"
                />
              </svg>
              <p
                v-if="terms && terms.opinion"
                class="opinionLink__text"
              >
                {{ terms.opinion }}
              </p>
            </routerLink>
          </div>
        </header>
        <b-tabs
          v-model="billboardTabIndex"
          class="billboard"
          content-class="billboard__tabContent"
          nav-wrapper-class="billboard__tabNav"
          fill
        >
          <b-tab :title="terms.thisWeek">
            <!-- 當周排行 -->
            <component
              :is="currBillboard"
              v-if="billboardTabIndex == 0"
              :mode="1"
            />
          </b-tab>
          <b-tab :title="terms.thisMonth">
            <!-- 當月排行 -->
            <component
              :is="currBillboard"
              v-if="billboardTabIndex == 1"
              :mode="2"
            />
          </b-tab>
          <b-tab :title="terms.lastMonth">
            <!-- 上月排行 -->
            <component
              :is="currBillboard"
              v-if="billboardTabIndex == 2"
              :mode="3"
            />
          </b-tab>
        </b-tabs>
      </div>
    </section>
    <section
      v-if="ready && site"
      id="allHistorySection"
      class="homeSection"
    >
      <div class="homeSection__inner">
        <header>
          <img
            v-if="site != 1"
            class="logo"
            alt="BS BET"
            width="132"
            height="57"
            :src="logoSrc"
          >
          <div
            v-if="site == 1"
            class="h2WithBG"
          >
            <h2>
              {{ terms.investigate
              }}<!-- 客服滿意度調查 -->
            </h2>
          </div>
          <h2 v-else>
            {{ terms.investigate
            }}<!-- 客服滿意度調查 -->
          </h2>
        </header>
        <div
          v-if="site != 3"
          class="allHistorySection__rule"
        >
          <h3>
            <span>{{ terms.voteDesc }}</span><!-- 評分規則說明 -->
          </h3>
          <ol>
            <li v-if="site != 5">
              {{ site == 1 ? terms.rule1_1 : terms.rule1 }}
            </li>
            <li>{{ terms.rule2 }}</li>
            <li>{{ terms.rule3 }}</li>
            <li>{{ terms.rule4 }}</li>
            <li>{{ terms.rule5 + (site == 5 ? terms.rulePS : "") }}</li>
          </ol>
          <p v-if="site != 5">
            <img
              class="allHistorySection__ruleHeart"
              :src="baseURL + 'img/heart.png'"
              alt=""
            >{{ terms.rulePS }}
          </p>
        </div>
        <template v-if="site">
          <TheLoadingRoller
            v-if="loadStatus == 1"
            class="allHistorySection__loading"
          />
          <div
            v-else
            class="allHistorySection__block"
          >
            <component
              :is="currAllMembers"
              :auth-state="authState"
            />
          </div>
        </template>
        <div
          v-if="site == 3"
          class="allHistorySection__rule"
        >
          <h3>
            {{ terms.voteDesc
            }}<!-- 評分規則說明 -->
          </h3>
          <ol>
            <li>{{ terms.rule2 }}</li>
            <li>{{ terms.rule3 }}</li>
            <li>{{ terms.rule4 }}</li>
            <li>{{ terms.rule5 + terms.rulePS }}</li>
          </ol>
        </div>
      </div>
      <div
        v-if="site == 1"
        class="privacyBar"
      >
        <router-link :to="{ name: 'Privacy' }">
          {{ terms.privacy }}
        </router-link>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import Billboard from '@/components/Billboard.vue'
import Billboard2 from '@/components/Billboard2.vue'
import Billboard3 from '@/components/Billboard3.vue'
import Billboard4 from '@/components/Billboard4.vue'
import Billboard5 from '@/components/Billboard5.vue'
import Billboard6 from '@/components/Billboard6.vue'
import Billboard7 from '@/components/Billboard7.vue'
import TheLoadingRoller from '@/components/TheLoadingRoller'
import AllMembers from '@/components/AllMembers'
import AllMembers2 from '@/components/AllMembers2'
import AllMembers3 from '@/components/AllMembers3'
import AllMembers4 from '@/components/AllMembers4'
import AllMembers5 from '@/components/AllMembers5'
import AllMembers6 from '@/components/AllMembers6'
import AllMembers7 from '@/components/AllMembers7'
import { EventBus } from '@/event-bus'
import { COOKIE_KIT } from '@/class/cookieKit'

export default {
  name: 'Home',
  components: {
    Billboard,
    Billboard2,
    Billboard3,
    Billboard4,
    Billboard5,
    Billboard6,
    Billboard7,
    TheLoadingRoller,
    AllMembers,
    AllMembers2,
    AllMembers3,
    AllMembers4,
    AllMembers5,
    AllMembers6,
    AllMembers7
  },
  data () {
    return {
      ready: false,
      billboardTabIndex: 0,
      baseURL: '',
      site1Terms: null,
      authState: 'gsbet'
    }
  },
  computed: {
    cssIdx () {
      const idxs = [null, 1, 8, 2, 3, 4, 6, 7]
      return idxs[this.site]
    },
    logoSrc () {
      return `${this.baseURL}img/logo${this.cssIdx}.png`
    },
    currBillboard () {
      return this.site === 1
        ? 'Billboard'
        : 'Billboard' + this.site
    },
    currAllMembers () {
      return this.site === 1
        ? 'AllMembers'
        : 'AllMembers' + this.site
    },
    ...mapState({
      loadStatus: (state) => state.allMembers.loadStatus,
      uid: (state) => state.uid,
      site: (state) => state.site,
      terms: (state) => state.terms
    })
  },
  created: async function () {
    const LANGS = [null, 'en', 'tw', 'tw', 'tw', 'tw', 'tw', 'tw']
    try {
      const TERMS_RESPONSE = await this.$store.dispatch('getTexts')
      const TERMS = TERMS_RESPONSE.data
      const terms = TERMS[LANGS[this.site]]
      const siteName = TERMS['siteName' + this.site]
      if (this.site === 1) {
        this.site1Terms = {}
        this.site1Terms.en = TERMS.en
        this.site1Terms.cn = TERMS.cn
      }
      this.$store.commit('SET_TERMS', terms)
      const notices = TERMS.notices[LANGS[this.site]]
      this.$store.commit('SET_NOTICES', notices)
      this.$store.commit('SET_SITENAME', siteName)

      document.title = terms.clerkGrade + ' | ' + siteName
    } catch (err) {
      EventBus.$emit('show-notice', { idx: 1, err: err.toString() })
    }

    EventBus.$on('refresh-list', this.getData)

    if (this.$route.query.code) {
      if (
        !this.$route.query.state ||
        this.$route.query.state !== this.authState
      ) {
        EventBus.$emit('show-notice', {
          idx: 3,
          err: this.$route.query.error.toString()
        })
      } else {
        try {
          const response1 = await this.$store.dispatch(
            'getAToken',
            this.$route.query.code
          )

          const opt = {}
          if (response1.data.token && response1.data.expires_in) {
            opt['max-age'] = response1.data.expires_in
            COOKIE_KIT.setCookie('la_token', response1.data.token, opt)
            window.location.replace('./')
          } else {
            EventBus.$emit('show-notice', { idx: 1, err: '' })
          }
        } catch (err) {
          EventBus.$emit('show-notice', { idx: 1, err: err.toString() })
        }
      }
    } else if (this.$route.query.error) {
      EventBus.$emit('show-notice', {
        idx: 3,
        err: this.$route.query.error.toString()
      })
    } else {
      this.ready = true
      this.getData()
    }
  },
  mounted () {
    this.baseURL = process.env.BASE_URL
  },
  beforeDestroy: function () {
    EventBus.$off('refresh-list', this.getData)
  },
  methods: {
    getData: async function () {
      try {
        const response = await this.$store.dispatch('getMembersSum', 2)
        const snap = response.data
        this.$store.commit('SET_All_MEMBERS', snap)
      } catch (err) {
        EventBus.$emit('show-notice', { idx: 4, err: err.toString() })
      }
    },
    swithLang (lang) {
      this.$store.commit('SET_TERMS', this.site1Terms[lang])
    }
  }
}
</script>

<style lang="scss">
@import "../scss/vars";
@import "../scss/mixins";

.wrapper {
  width: 100%;
  max-width: 500px;
  min-height: 100%;
  margin: auto;
  padding: 0;

  .homeSection {
    padding: $pt-page 20px 20px;
    background: {
      color: transparent;
      image: url(../assets/bg-wrapper5.jpg);
      position: center 0;
      repeat: no-repeat;
      size: cover;
    }
  }
  .billboard__tabContent {
    margin-top: 1rem;
  }

  @media (max-width: $maxW-xxs) {
    .homeSection {
      padding: $pt-page 12px 20px;
    }
  }
}
.firstHeader {
  position: relative;

  .headerBar {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    position: absolute;
    right: 0;
    top: 0;
  }

  .langSwitcher__btn {
    background-color: transparent;
    border: none;
    text-decoration: underline;
    color: $color-white;
    font-size: 0.7rem;
  }
}

.opinionLink {
  display: inline-block;
  width: 50px;
  height: 42px;
  text-decoration: none;
  text-align: center;
  color: #ff0;
  background: {
    color: transparent;
    position: center center;
    repeat: no-repeat;
    size: contain;
  }

  svg {

  margin-left: 10px;
  margin-right: 10px;
  }

  &:hover,
  &:active {
    text-decoration: none;
  }

  &__text {
    font-size: 12px;
    line-height: 1;
    text-shadow: 1px 1px rgba(0,0,0,.5);
  }
}

.logo {
  width: 200px;
  height: 57px;
  margin-bottom: 0;
}

h2 {
  font-size: 0.8rem;
  padding-top: 6px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.goldBorder {
  border: 1px solid $color-gold;
}

#billboard {
  .nav-tabs {
    border: none;

    .nav-item {
      margin-bottom: 0;
    }
    .nav-item + .nav-item {
      border-left: 1px solid $color-white;
    }

    .nav-link {
      color: $color-white;
      margin-top: -0.5rem;
      margin-bottom: -0.5rem;
      font-size: 0.6rem;

      &:hover,
      &:focus {
        border-color: transparent;
      }
    }
    .nav-link.active,
    .nav-item.show .nav-link {
      background-color: transparent;
      border: none;
      color: $color-yellow;
    }
  }
}

#allHistorySection {
  padding-bottom: 1.5rem;
}

.allHistorySection__rule {
  border: 1px solid $color-gold;
  background-color: $color-black;
  padding: 10px 20px;

  h3,
  ol,
  p {
    text-align: left;
    font-size: 0.6rem;
    margin: 0;
  }
  ol {
    padding-left: 20px;
  }
  .allHistorySection__ruleHeart {
    display: inline-block;
    margin-right: 5px;
    height: 15px;
    margin-bottom: 0;
  }
}
.allHistorySection__loading {
  margin: auto;
  margin: 10% auto;
}
.allHistorySection__block {
  margin-top: 0.75rem;
  border: 1px solid $color-gold;
  background-color: $color-black;
  padding: 0 15px 0 20px;
}

.wrapper.homeWrapper--1 {
  .h2WithBG {
    position: relative;
    width: 80%;
    height: 0;
    padding-bottom: 38%;
    margin: auto;
    background: {
      image: url(../assets/logo5.png);
      position: center center;
      repeat: no-repeat;
      size: contain;
    }

    h2 {
      position: absolute;
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 0;
      margin-bottom: 0;
      width: 100%;
      top: 53%;
      text-align: center;
      font-size: 1.1rem;
      text-shadow: 1px -1px 1px $color-purple-site2,
        -1px -1px 1px $color-purple-site2, -1px 1px 1px $color-purple-site2,
        1px 1px 1px $color-purple-site2;
    }
  }

  #billboard {
    .nav-tabs {
      border: none;

      .nav-item + .nav-item {
        border-left: none;
      }

      .nav-link {
        color: $color-white;
        display: inline-block;
        padding: 0.5rem 0;
        font-size: 0.6rem;
        font-weight: bold;
        text-shadow: 0.5px -0.5px 0.5px $color-purple-site2,
          -0.5px -0.5px 0.5px $color-purple-site2,
          -0.5px 0.5px 0.5px $color-purple-site2,
          0.5px 0.5px 0.5px $color-purple-site2;

        &:hover,
        &:focus {
          border-color: transparent;
        }
      }
      .nav-link.active,
      .nav-item.show .nav-link {
        background-color: transparent;
        border: none;
        position: relative;

        &:after {
          display: inline-block;
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          height: 2px;
          background-color: $color-purple-site2;
          width: 100%;
        }
      }
    }
  }
  .allHistorySection__rule {
    border: none;
    background-color: transparent;
    padding: 20px 10px;
    h3,
    ol,
    p {
      text-align: center;
      line-height: 1.75;
    }
    ol {
      list-style-position: inside;
    }
    h3 {
      font-size: 0.7rem;
      span {
        text-shadow: 0.5px -0.5px 0.5px $color-purple-site2,
          -0.5px -0.5px 0.5px $color-purple-site2,
          -0.5px 0.5px 0.5px $color-purple-site2,
          0.5px 0.5px 0.5px $color-purple-site2;
      }

      &:before,
      &:after {
        content: "";
        display: inline-block;
        width: 24px;
        height: 24px;
        vertical-align: middle;
        background: {
          color: transparent;
          image: url(../assets/gbet-bling.png);
          position: center center;
          repeat: no-repeat;
          size: contain;
        }
      }
      &:after {
        transform: scaleX(-100%);
      }
    }
    .allHistorySection__ruleHeart {
      display: none;
    }
  }
  .allHistorySection__block {
    position: relative;
    border: 1px solid #6fa4f7;
    background: linear-gradient(to bottom, #c842db3d 10%, #00000005 60%);
    padding-bottom: 20px;

    &:after {
      content: "";
      position: absolute;
      left: -7px;
      bottom: -17px;
      width: 42px;
      height: 50px;
      background: {
        color: transparent;
        image: url(../assets/gbet-deco.png);
        position: center center;
        repeat: no-repeat;
        size: contain;
      }
    }
  }

  @media (max-width: $maxW-xxs) {
    .h2WithBG {
      h2 {
        top: 51%;
        font-size: 1rem;
      }
    }
    .allHistorySection__block {
      padding-bottom: 15px;
    }
  }
  @media (max-width: $maxW-xxxs) {
    .h2WithBG {
      h2 {
        top: 49%;
        font-size: 0.9rem;
      }
    }
    .allHistorySection__block {
      &:after {
        left: -5px;
        bottom: -13px;
        width: 32px;
        height: 40px;
      }
    }
  }
}

.wrapper.homeWrapper--2 {
  .homeSection {
    padding: 20px;
    background-image: url(../assets/bg-site-2-new.jpg);
    background-size: cover;
  }

  .logo {
    width: 180px;
    height: auto;
  }

  h2 {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    filter: drop-shadow(0px 0px 2px #dd2222) drop-shadow(0px 0px 3px #dd2222);
  }

  #billboard {
    .nav-tabs {
      .nav-item + .nav-item {
        border-left: 1px solid $color-white;
      }

      .nav-link {
        color: $color-white;
      }
      .nav-link.active,
      .nav-item.show .nav-link {
        color: #6af;
      }
    }
  }

  .allHistorySection__rule {
    border: 2px solid #ffffff80;
    background: linear-gradient(180deg, #1100ee33, rgba(14, 29, 74, 0.2));
    padding: 20px 10px;
    h3,
    ol,
    p {
      text-align: center;
      line-height: 1.75;
    }
    ol {
      list-style-position: inside;
    }
    h3 {
      font-size: 0.7rem;
      span {
        filter: drop-shadow(0px 0px 2px #0077ff) drop-shadow(0px 0px 3px #0077ff);
      }

      &:before,
      &:after {
        content: "";
        display: inline-block;
        width: 24px;
        height: 24px;
        vertical-align: middle;
        background: {
          color: transparent;
          image: url(../assets/gbet-bling.png);
          position: center center;
          repeat: no-repeat;
          size: contain;
        }
      }
      &:after {
        transform: scaleX(-100%);
      }
    }
    .allHistorySection__ruleHeart {
      display: none;
    }
  }

  .allHistorySection__block {
    position: relative;
    border: 2px solid #ffffff80;
    background: linear-gradient(180deg, #1100ee33, rgba(14, 29, 74, 0.2));
    padding-bottom: 20px;

    // &:after {
    //   content: "";
    //   position: absolute;
    //   left: -7px;
    //   bottom: -17px;
    //   width: 42px;
    //   height: 50px;
    //   background: {
    //     color: transparent;
    //     image: url(../assets/gbet-deco.png);
    //     position: center center;
    //     repeat: no-repeat;
    //     size: contain;
    //   }
    // }
  }
}

.wrapper.homeWrapper--3 {
  .homeSection {
    padding: 0 0 20px;
    background-image: url(../assets/bg-wrapper2.jpg);
  }
  .homeSection__inner {
    padding: $pt-page 20px 20px;
    background: {
      color: transparent;
      image: url(../assets/bg-billboard2.png);
      position: center 0;
      repeat: no-repeat;
      size: 100% auto;
    }
  }
  h2 {
    text-shadow: 1px -1px 1px #a2851a, -1px -1px 1px #a2851a,
      -1px 1px 1px #a2851a, 1px 1px 1px #a2851a;
  }
  .logo {
    width: 280px;
    height: auto;
  }

  #billboard {
    .nav-tabs {
      border: none;

      .nav-item + .nav-item {
        border-left: none;
      }

      .nav-link {
        color: $color-white;
        display: inline-block;
        padding: 0.5rem 0;
        font-size: 0.6rem;
        font-weight: bold;

        &:hover,
        &:focus {
          border-color: transparent;
        }
      }
      .nav-link.active,
      .nav-item.show .nav-link {
        background-color: transparent;
        border: none;
        color: $color-yellow3;
        position: relative;

        &:after {
          display: inline-block;
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          height: 2px;
          background-color: $color-yellow3;
          width: 100%;
        }
      }
    }
  }

  .allHistorySection__rule {
    border: none;
    background-color: transparent;
    margin-top: $pt-page;

    h3 {
      color: $color-yellow3;
    }
  }

  .allHistorySection__block {
    border: none;
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
  }
}
.wrapper.homeWrapper--4 {
  .homeSection {
    background-image: url(../assets/bg-wrapper3.jpg);
  }

  .allHistorySection__rule {
    border: 1px solid $color-lightOrange;
  }

  .allHistorySection__block {
    border: 1px solid $color-lightOrange;
  }
}
.wrapper.homeWrapper--5 {
  .homeSection {
    background-image: url(../assets/bg-wrapper4.jpg);

    h2 {
      display: block;
      line-height: 2;
      text-indent: -9999px;
      margin-bottom: 30px;
      background: {
        color: transparent;
        image: url(../assets/service_investigate5.png);
        position: center 0;
        repeat: no-repeat;
        size: 80% auto;
      }
    }
    &:nth-child(2) {
      h2 {
        margin-bottom: 15px;
        background: {
          image: url(../assets/service_rank5.png);
          size: 65% auto;
        }
      }
    }
  }
  .logo {
    width: 70px;
    height: auto;
    margin-top: 30px;
  }

  .billboard__tabContent {
    margin-top: 1.5rem;
  }
  #billboard {
    .nav-tabs {
      .nav-item + .nav-item {
        border-left: 4px solid $color-blue-5;
      }

      .nav-link {
        font-size: 0.7rem;
        font-weight: bold;
        text-shadow: 2px 2px 8px lighten($color-blue-5, 10%),
          -2px -2px 8px lighten($color-blue-5, 10%),
          -2px 2px 8px lighten($color-blue-5, 10%),
          2px -2px 8px lighten($color-blue-5, 10%);

        &:hover,
        &:focus {
          border-color: transparent;
        }
      }
      .nav-link.active,
      .nav-item.show .nav-link {
        color: $color-yellow;
      }
    }
  }

  .allHistorySection__rule {
    border: none;
    background-color: transparent;
    padding: 20px 10px;

    h3,
    ol {
      padding-left: 0;
      text-align: center;
      list-style-position: inside;
    }
    h3 {
      font-size: 0.8rem;
      margin-bottom: 10px;
      font-weight: bold;
      text-shadow: 2px 2px 8px lighten($color-blue-5, 10%),
        -2px -2px 8px lighten($color-blue-5, 10%),
        -2px 2px 8px lighten($color-blue-5, 10%),
        2px -2px 8px lighten($color-blue-5, 10%);
    }
  }

  .allHistorySection__block {
    border: none;
    background-color: transparent;
  }

  @media (max-width: $maxW-xxs) {
    .homeSection {
      h2 {
        margin-bottom: 10px;
      }
    }
    #billboard {
      .nav-tabs {
        .nav-item + .nav-item {
          border-left: 2px solid $color-blue-5;
        }
        .nav-link {
          font-size: 0.6rem;
        }
      }
    }
  }
}

.wrapper.homeWrapper--6 {
  .homeSection {
    background-image: url(../assets/bg-wrapper6.jpg);
  }
  h2 {
    text-shadow: 2px -2px 2px $color-purple-site6,
      -2px -2px 2px $color-purple-site6, -2px 2px 2px $color-purple-site6,
      2px 2px 2px $color-purple-site6;
  }
  #billboard {
    .nav-tabs {
      .nav-link.active,
      .nav-item.show .nav-link {
        color: $color-green-site6;
      }
    }
  }
  .allHistorySection__rule,
  .allHistorySection__block {
    border-color: $color-purple-site6;
    background-color: $semi-black-site6;
  }
  .allHistorySection__rule {
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      right: -15px;
      bottom: -5px;
      width: 40px;
      height: 40px;
      background: {
        image: url(../assets/icon-pencel-6.png);
        position: 0 0;
        repeat: no-repeat;
        size: 100% 100%;
      }
    }
  }
}
.wrapper.homeWrapper--7 {
  .homeSection {
    background-image: url(../assets/bg-wrapper7.jpg);
    background-size: cover;
  }
  h2 {
    text-shadow: 0.1rem 0.1rem 0.1rem #000;
    margin-top: 0;
    padding-top: 0;
  }
  #billboard {
    .nav-tabs {
      .nav-link.active,
      .nav-item.show .nav-link {
        color: $color-brightgreen-site7;
        text-shadow: 0.05rem 0.05rem 0.05rem #000,
          -0.05rem -0.05rem 0.05rem #000, -0.05rem 0.05rem 0.05rem #000,
          0.05rem -0.05rem 0.05rem #000;
      }
    }
  }
  .allHistorySection__rule,
  .allHistorySection__block {
    @include blockStyle7;
  }
}
.wrapper.homeWrapper--1 {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
.privacyBar {
  font-size: 0.6rem;
  text-align: right;
  a {
    color: $color-white;
    text-decoration: underline;
  }
}

.hiddenSvg {
  display: none;
}

.st0{opacity:0.5;}
.st1{fill:#ff0;}

.homeWrapper--1,
.homeWrapper--5 {
  .opinionLink {
    display: none;
  }
}

.homeWrapper--2{
  .st1 {
    fill: #0077ff;
  }

  .opinionLink{
    color: #0077ff;

    &:hover,
    &:active {
      color: lighten(#0077ff, 10%);
    }
  }
}

.homeWrapper--3{
  .st1 {
    fill: $color-yellow3;
  }
  .opinionLink{
    color: $color-yellow3;

    &:hover,
    &:active {
      color: lighten($color-yellow3, 10%);
    }
  }
}

.homeWrapper--4{
  .st1 {
    fill: $color-lightOrange;
  }
  .opinionLink{
    color: $color-lightOrange;

    &:hover,
    &:active {
      color: lighten($color-lightOrange, 10%);
    }
  }
}

.homeWrapper--6{
  .st1 {
    fill: $color-green-site6;
  }
  .opinionLink{
    color: $color-green-site6;

    &:hover,
    &:active {
      color: lighten($color-green-site6, 10%);
    }
  }
}

.homeWrapper--7{
  .st1 {
    fill: $color-green-site7;
  }
  .opinionLink{
    color: $color-green-site7;

    &:hover,
    &:active {
      color: lighten($color-green-site7, 10%);
    }
  }
}
</style>
